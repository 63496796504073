import * as userUtils from "@/store/userUtils";

const getCurrentPermission = (route) => {
  try {
    //console.log("route", route);
    if (route.meta) {
      const permissions = userUtils.getPermission();

      const permission = permissions.find((value) => {
        return value.menuId === route.meta.menuId;
      });

      // console.log("currentMenu", permission, permissions);

      if (permission) {
        return permission;
      } else {
        return {
          isCanAdd: false,
          isCanDelete: false,
          isCanExport: false,
          isCanUpdate: false,
          isCanView: false,
        };
      }
    }
  } catch (ex) {
    return {
      isCanAdd: false,
      isCanDelete: false,
      isCanExport: false,
      isCanUpdate: false,
      isCanView: false,
    };
  }
};
const getFirstMenuPermission = () => {
  const currentUser = userUtils.getCurrentUser();
  if (currentUser && currentUser.menus) {
    return currentUser?.menus[0]?.menuModels[1]?.tmMenu;
  } else return null;
};

export { getCurrentPermission, getFirstMenuPermission };

import store from "@/store";
import moment from "moment";

import appsetting from "@/core/appsetting";
import * as axiosUtils from "@/core/services/axiosUtils";

const getCurrentUser = () => {
  return store.getters.currentUser;
};

const getCurrentUserId = () => {
  const user = getCurrentUser();
  return user.id;
};

const getCurrentToken = () => {
  const user = getCurrentUser();
  return user.api_token;
};

const getExpiredDate = () => {
  const userData = JSON.parse(window.localStorage.getItem("userData") || "{}");
  return userData.expiredDate;
};

const getPermission = () => {
  const user = getCurrentUser();
  return user.permissions;
};

const isLoggedIn = () => {
  try {
    const expiredDate = getExpiredDate();
    console.log("isLoggedIn expiredDate", expiredDate);
    if (moment().valueOf() > expiredDate || expiredDate === undefined) {
      return false;
    }

    return true;
  } catch (ex) {
    console.log("ex", ex);
    return false;
  }
};

const getCurrentDepartmentId = () => {
  const user = getCurrentUser();
  return user.department?.value;
};

const getCurrentDepartmentRefId = () => {
  const user = getCurrentUser();
  console.log("user.department", user.department.refId);
  return user.department?.refId;
};

const getCurrentPermissionGroupId = () => {
  const user = getCurrentUser();
  return user?.permissionGroup?.value; // ปรับตอนทำแจ้งเตือน ใช้สำหรับเพิ่ม permission ลง header
  // return user.permissionGroup.value;
};

const getCurrentPermissionGroupRefType = () => {
  const user = getCurrentUser();
  return user.permissionGroup.refType;
};

const getCurrentPermissionRole = async () => {
  return await axiosUtils
    .axiosGet(
      appsetting.backendAPI.url +
        "api/PermissionGroup/GetPermissionRole?id=" +
        getCurrentPermissionGroupId()
    )
    .then((response) => {
      if (response.data.isSuccess) {
        // console.log("getCurrentPermissionRole response", response.data.data);
        return response.data.data === "Admin";
      }
    })
    .catch((err) => {
      console.log("getCurrentPermissionRole err", err);
      return false;
    });
};

const getCurrentPermissionRoleType = async () => {
  return await axiosUtils
    .axiosGet(
      appsetting.backendAPI.url +
        "api/PermissionGroup/GetPermissionRole?id=" +
        getCurrentPermissionGroupId()
    )
    .then((response) => {
      if (response.data.isSuccess) {
        // console.log("getCurrentPermissionRole response", response.data.data);
        return response.data.data;
      }
    })
    .catch((err) => {
      console.log("getCurrentPermissionRoleType err", err);
      return false;
    });
};

export {
  getCurrentUser,
  getCurrentUserId,
  getCurrentToken,
  getPermission,
  isLoggedIn,
  getCurrentDepartmentId,
  getCurrentPermissionGroupId,
  getCurrentPermissionGroupRefType,
  getCurrentPermissionRole,
  getCurrentPermissionRoleType,
  getCurrentDepartmentRefId,
};

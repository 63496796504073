import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import moment from "moment";
import * as userUtils from "@/store/userUtils";

export interface User {
  id: string;
  name: string;
  surname: string;
  email: string;
  password: string;
  api_token: string;
}

export interface UserAuthInfo {
  errors: unknown;
  user: User;
  department: object;
  departments: object[];
  permissionGroup: object;
  isAuthenticated: boolean;
  layoutMenus: object[];
}

@Module
export default class AuthModule extends VuexModule implements UserAuthInfo {
  errors = {};
  user = {} as User;
  department = {};
  departments = [];
  permissionGroup = {};
  //isAuthenticated = !!JwtService.getToken();
  isAuthenticated = userUtils.isLoggedIn();
  layoutMenus = [];

  /**
   * Get current user object
   * @returns User
   */
  get currentUser(): User {
    return this.user;
  }

  /**
   * Verify user authentication
   * @returns boolean
   */
  get isUserAuthenticated(): boolean {
    return this.isAuthenticated;
  }

  /**
   * Get authentification errors
   * @returns array
   */
  get getErrors() {
    return this.errors;
  }

  get currentLayoutMenus() {
    const layoutMenus = JSON.parse(
      window.localStorage.getItem("layoutMenus") || "{}"
    );
    return layoutMenus;
  }

  @Mutation
  [Mutations.SET_ERROR](error) {
    this.errors = { ...error };
  }

  @Mutation
  [Mutations.SET_AUTH](user) {
    this.isAuthenticated = true;
    this.user = user;
    this.errors = {};
    JwtService.saveToken(user.api_token);
    console.log("SET_AUTH", user);
    window.localStorage.setItem("userData", JSON.stringify(user));
  }

  @Mutation
  [Mutations.SET_USER](user) {
    this.user = user;
  }

  @Mutation
  [Mutations.SET_PASSWORD](password) {
    this.user.password = password;
  }

  @Mutation
  [Mutations.PURGE_AUTH]() {
    this.isAuthenticated = false;
    this.user = {} as User;
    this.errors = [];
    JwtService.destroyToken();
    window.localStorage.removeItem("userData");
  }

  @Mutation
  [Mutations.SET_LAYOUT_MENU](menus) {
    console.log("SET_LAYOUT_MENU", menus);
    this.layoutMenus = menus;
    window.localStorage.setItem("layoutMenus", JSON.stringify(menus));
  }

  @Action
  [Actions.LOGIN](payload) {
    const expiredDate = moment().add(payload.token.expireIn - 3, "minutes");
    // login successful then set auth
    const user = {
      id: payload.user.id,
      name: payload.user.fname,
      surname: payload.user.lname,
      email: payload.user.email,
      api_token: payload.token.token,
      expiredDate: expiredDate.valueOf(),
      department: payload.defaultDepartment
        ? payload.defaultDepartment
        : payload.departments[0],
      departments: payload.departments,
      permissionGroup: payload.defaultPermissionGroup
        ? payload.defaultPermissionGroup
        : payload.departments[0].permissionGroups[0],
    };
    //console.log("SET_AUTH", user, payload);
    this.context.commit(Mutations.SET_AUTH, user);
  }

  @Action
  [Actions.LOGOUT]() {
    this.context.commit(Mutations.PURGE_AUTH);
  }

  @Action
  [Actions.REGISTER](credentials) {
    return ApiService.post("register", credentials)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_AUTH, data);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }

  @Action
  [Actions.FORGOT_PASSWORD](payload) {
    return ApiService.post("forgot_password", payload)
      .then(() => {
        this.context.commit(Mutations.SET_ERROR, {});
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }

  @Action
  [Actions.VERIFY_AUTH]() {
    // verify_token ทุกๆ การเปลี่ยน router
    if (userUtils.isLoggedIn()) {
      const userData = JSON.parse(
        window.localStorage.getItem("userData") || "{}"
      );
      console.log("VERIFY_AUTH", userData);
      this.context.commit(Mutations.SET_AUTH, userData);
    } else {
      this.context.commit(Mutations.PURGE_AUTH);
    }
    // if (JwtService.getToken()) {
    //   ApiService.setHeader();
    //   ApiService.post("verify_token", payload)
    //     .then(({ data }) => {
    //       this.context.commit(Mutations.SET_AUTH, data);
    //     })
    //     .catch(({ response }) => {
    //       this.context.commit(Mutations.SET_ERROR, response.data.errors);
    //       this.context.commit(Mutations.PURGE_AUTH);
    //     });
    // } else {
    //   this.context.commit(Mutations.PURGE_AUTH);
    // }
  }
}

import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import store from "@/store";
import { Mutations, Actions } from "@/store/enums/StoreEnums";
import JwtService from "@/core/services/JwtService";

import * as permissionUtils from "@/router/permissionUtils";

import routeExtract from "./route-extract";

const routeMenus = await routeExtract();

//console.log("routeMenus", routeMenus);

routeMenus.push(
  ...[
    {
      path: "/",
      component: () => import("@/layouts/SystemLayout.vue"),
      children: [
        {
          // the 404 route, when none of the above matches
          path: "/404",
          name: "404",
          component: () =>
            import("@/views/crafted/authentication/Error404.vue"),
          meta: {
            pageTitle: "Error 404",
          },
        },
        {
          path: "/500",
          name: "500",
          component: () =>
            import("@/views/crafted/authentication/Error500.vue"),
          meta: {
            pageTitle: "Error 500",
          },
        },
      ],
    },
    {
      path: "/:pathMatch(.*)*",
      redirect: "/404",
    },
  ]
);

const routes: Array<RouteRecordRaw> = routeMenus;

const router = createRouter({
  // history: createWebHashHistory(),
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  // current page view title
  document.title = `${to.meta.pageTitle} - ${process.env.VUE_APP_NAME}`;

  // reset config to initial state
  store.commit(Mutations.RESET_LAYOUT_CONFIG);

  // verify auth token before each page change
  store.dispatch(Actions.VERIFY_AUTH, { api_token: JwtService.getToken() });

  // before page access check if page requires authentication
  if (to.meta.middleware == "auth") {
    const fisrtMenu = permissionUtils.getFirstMenuPermission();
    if (store.getters.isUserAuthenticated) {
      // next check permission
      const permissionObject = permissionUtils.getCurrentPermission(to);
      if (permissionObject) {
        console.log("permissionObject", to);
        if (permissionObject.isCanView === true) {
          next();
        } else if (to.name === "user-edit") {
          //All user can edit data own their
          next();
        } else {
          if (to.name === "dashboard" && fisrtMenu === "dashboard")
            next({ name: "dashboard" });
          else {
            if (fisrtMenu) {
              next({ name: fisrtMenu.routeName });
            } else next();
          }
        }
      }
    } else {
      next({ name: "sign-in" });
    }
  } else {
    next();
  }

  // Scroll page to top on every route change
  window.scrollTo({
    top: 0,
    left: 0,
    behavior: "smooth",
  });
});

export default router;

import Swal from "sweetalert2/dist/sweetalert2.min.js";

export default {
  methods: {
    sweetAlertSuccess(
      message = "บันทึกข้อมูลสำเร็จ",
      btnMessage = "ยืนยัน",
      onClickMethod = null
    ) {
      if (onClickMethod === null) {
        Swal.fire({
          text: message,
          icon: "success",
          buttonsStyling: false,
          confirmButtonText: btnMessage,
          customClass: {
            confirmButton: "btn fw-semobold btn-light-primary",
          },
        });
      } else {
        Swal.fire({
          text: message,
          icon: "success",
          buttonsStyling: false,
          confirmButtonText: btnMessage,
          customClass: {
            confirmButton: "btn fw-semobold btn-light-primary",
          },
        }).then(onClickMethod);
      }
    },
    sweetAlertError(
      message = "บันทึกข้อมูลไม่สำเร็จ",
      btnMessage = "ยืนยัน",
      onClickMethod = null
    ) {
      if (onClickMethod === null) {
        Swal.fire({
          text: message,
          icon: "error",
          buttonsStyling: false,
          confirmButtonText: btnMessage,
          customClass: {
            confirmButton: "btn fw-semobold btn-light-danger",
          },
        });
      } else {
        Swal.fire({
          text: message,
          icon: "error",
          buttonsStyling: false,
          confirmButtonText: btnMessage,
          customClass: {
            confirmButton: "btn fw-semobold btn-light-danger",
          },
        }).then(onClickMethod);
      }
    },
  },
};

import * as axiosUtils from "@/core/services/axiosUtils";

//import * as localStorageUtils from '/src/auth/localStorageUtils'

import appsetting from "@/core/appsetting";

const getRouteMenu = async () => {
  const response = await axiosUtils.axiosGet(
    appsetting.backendAPI.url + "api/RouteMenu"
  );

  return response;
};

export { getRouteMenu };

const appsetting = {
  // backendAPI: {
  //   // url: "http://202.170.120.179/nhrc_backend_api/",
  //   //url: "https://localhost:44357/",
  //   url: "https://hrcdbapi.nhrc.or.th/APIOfficerMirror/",
  //   apiKey: "fc29c58d-3e3d-4af4-9ce7-d15d3af77dfa",
  //   password: "b8efdbf7-41bf-4ed8-a86a-a99f95fa37e7",
  // },
  // document: {
  //   url: "https://hrcdbapi.nhrc.or.th/mirror/",
  //   //url: "http://202.170.120.179:8086/",
  //   //url: "https://localhost:44354/",
  // },

  //Deploy For Production//
  backendAPI: {
    url: "https://hrcdbapi.nhrc.or.th/APIOfficer/",
    apiKey: "fc29c58d-3e3d-4af4-9ce7-d15d3af77dfa",
    password: "b8efdbf7-41bf-4ed8-a86a-a99f95fa37e7",
  },
  document: {
    url: "https://hrcdbapi.nhrc.or.th/",
  },
};

export default appsetting;

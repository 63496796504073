import * as apiRouteMenu from "@/core/services/routeMenu";

const getRouteMenuFromAPI = async () => {
  const response = await apiRouteMenu.getRouteMenu();
  if (response.data.isSuccess === true) {
    return extractFromAPI(response.data.data);
  } else {
    return [];
  }
};

const extractFromAPI = (routeMenus) => {
  const routeResults = [];
  routeMenus.forEach((level0) => {
    const level0Result = extractRouteMenuObject(level0);
    routeResults.push(level0Result);
  });
  return routeResults;
};

const extractRouteMenuObject = (routeMenuObject) => {
  const routeMenu = {};
  if (routeMenuObject.routePath) {
    routeMenu.path = routeMenuObject.routePath;
  }
  if (routeMenuObject.routeName) {
    routeMenu.name = routeMenuObject.routeName;
  }
  if (routeMenuObject.redirect) {
    routeMenu.redirect = routeMenuObject.redirect;
  }
  if (routeMenuObject.routeComponent) {
    const cutPath = routeMenuObject.routeComponent
      .replace("@/", "")
      .replace(".vue", "");
    routeMenu.component = () => import(`@/${cutPath}.vue`);
  }
  if (routeMenuObject.routeMeta) {
    routeMenu.meta = JSON.parse(
      routeMenuObject.routeMeta.replace(/(\r\n|\n|\r)/gm, "")
    );
  }
  if (routeMenuObject.menuId) {
    if (!routeMenu.meta) {
      routeMenu.meta = {};
    }
    routeMenu.meta.menuId = routeMenuObject.menuId;
  }
  if (routeMenuObject.children) {
    const childrens = [];
    routeMenuObject.children.forEach((level1) => {
      const level1Result = extractRouteMenuObject(level1);
      childrens.push(level1Result);
    });
    routeMenu.children = childrens;
  }
  return routeMenu;
};

export default getRouteMenuFromAPI;
